import React, { useState } from 'react';
import TouchKeyboard from './TouchKeyboard';
import './LimitSelection.css';

const LimitSelection = ({ operations, setLimits, onProceed }) => {
  const [limits, setLimitValues] = useState(
    operations.reduce((acc, op) => {
      acc[op] = '';
      return acc;
    }, {})
  );

  const operationLabels = {
    addition: 'SČÍTÁNÍ - vyber nejvyšší číslo, s kterým chceš počítat',
    subtraction: 'ODČÍTÁNÍ - vyber nejvyšší číslo, s kterým chceš počítat',
    multiplication: 'NÁSOBENÍ - vyber nejvyšší číslo, s kterým chceš počítat',
    division: 'DĚLENÍ - vyber nejvyšší číslo, s kterým chceš počítat',
    rounding: 'ZAOKROUHLOVÁNÍ - vyber nejvyšší číslo, s kterým chceš počítat'
  };

  const handleKeyPress = (key, operation) => {
    if (key === 'Backspace') {
      setLimitValues((prevLimits) => ({
        ...prevLimits,
        [operation]: prevLimits[operation].slice(0, -1)
      }));
    } else if (key === 'OK') {
      // no action needed for OK
    } else {
      setLimitValues((prevLimits) => ({
        ...prevLimits,
        [operation]: prevLimits[operation] + key
      }));
    }
  };

  const handleProceed = () => {
    setLimits(limits);
    onProceed();
  };

  const handleButtonClick = (value, operation) => {
    setLimitValues((prevLimits) => ({
      ...prevLimits,
      [operation]: value
    }));
  };

  return (
    <div className="limit-selection">
      <h2>Nastavte horní hranici pro operace</h2>
      {operations.map((op) => (
        <div key={op} className="limit-input-container">
          <label>
            {operationLabels[op]}
            <div className="input-display">{limits[op]}</div>
            {op === 'rounding' ? (
              <div className="button-group">
                {[100, 1000, 10000].map((limit) => (
                  <button
                    key={limit}
                    onClick={() => handleButtonClick(limit.toString(), op)}
                    className={`limit-button ${limits[op] === limit.toString() ? 'selected' : ''}`}
                  >
                    {limit}
                  </button>
                ))}
              </div>
            ) : (
              <TouchKeyboard onKeyPress={(key) => handleKeyPress(key, op)} />
            )}
          </label>
        </div>
      ))}
      <button onClick={handleProceed}>Pokračovat</button>
      <p className="help-text">
        Tak a poslední nastavení. Tady si vyber, jaké nejvyšší číslo bude v příkladech, které budeš počítat. Počítáte zatím jen do pětky? Vyber číslo 5. Příklady tak nikdy nebudou obsahovat vyšší číslo.
      </p>
    </div>
  );
};

export default LimitSelection;
