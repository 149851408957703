import React, { useState, useEffect } from 'react';
import TouchKeyboard from './TouchKeyboard';
import './PracticeMode.css';

const PracticeMode = ({ upperLimit, operations, onExit }) => {
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [questionsAsked, setQuestionsAsked] = useState([]);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isPracticeComplete, setIsPracticeComplete] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);

  const totalQuestions = operations.length >= 3 ? 30 : 20;
  const questionsPerOperation = Math.ceil(totalQuestions / operations.length);

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    if (questionIndex >= totalQuestions && incorrectQuestions.length === 0) {
      setIsPracticeComplete(true);
      setTimeout(onExit, 10000); // Zobrazí zprávu na 10 sekund a pak se vrátí na úvodní obrazovku
      return;
    }

    if (incorrectQuestions.length > 0 && questionsAsked.length > 2) {
      const [nextIncorrectQuestion] = incorrectQuestions.splice(0, 1);
      setQuestion(nextIncorrectQuestion);
    } else {
      let newQuestion;
      do {
        newQuestion = createQuestion();
      } while (questionsAsked.some(q => q.question === newQuestion.question));

      setQuestion(newQuestion);
      setQuestionsAsked([...questionsAsked, newQuestion].slice(-totalQuestions));
    }

    setAnswer('');
    setFeedback(null);
    setCorrectAnswer('');
    setQuestionIndex(prevIndex => prevIndex + 1);
    setInputDisabled(false); // Povolit vstup pro novou otázku
  };

  const createQuestion = () => {
    const operation = operations[questionIndex % operations.length];
    let a = Math.floor(Math.random() * upperLimit[operation]) + 1;
    let b = Math.floor(Math.random() * upperLimit[operation]) + 1;
    let question = '';
    let answer = 0;

    switch (operation) {
      case 'addition':
        question = `${a} + ${b}`;
        answer = a + b;
        break;
      case 'subtraction':
        if (a < b) [a, b] = [b, a]; // Ensure positive result
        question = `${a} - ${b}`;
        answer = a - b;
        break;
      case 'multiplication':
        question = `${a} x ${b}`;
        answer = a * b;
        break;
      case 'division':
        while (a % b !== 0) {
          a = Math.floor(Math.random() * upperLimit[operation]) + 1;
          b = Math.floor(Math.random() * upperLimit[operation]) + 1;
        }
        question = `${a} / ${b}`;
        answer = a / b;
        break;
      case 'rounding':
        let num = Math.floor(Math.random() * upperLimit[operation]) + 1;
        let rounded = Math.round(num / 10) * 10;
        question = `${num} ≐ ?`;
        answer = rounded;
        break;
      default:
        break;
    }
    return { question, answer };
  };

  const checkAnswer = () => {
    const correct = parseInt(answer) === question.answer;
    setInputDisabled(true); // Zakázat vstup během zpětné vazby
    if (correct) {
      setFeedback('Správně!');
      setTimeout(generateQuestion, 3000); // Zobrazí zpětnou vazbu na 3 vteřiny
    } else {
      setFeedback('Chybně!');
      setCorrectAnswer(question.question.replace('?', question.answer));
      setIncorrectQuestions([
        ...incorrectQuestions,
        question
      ]);
      setTimeout(generateQuestion, 7000); // Zobrazí zpětnou vazbu na 7 vteřin
    }
  };

  const handleKeyPress = (key) => {
    if (inputDisabled) return; // Ignorovat vstup, pokud je vstup zakázán
    if (key === 'Backspace') {
      setAnswer(answer.slice(0, -1));
    } else if (key === 'OK') {
      checkAnswer();
    } else {
      setAnswer(answer + key);
    }
  };

  return (
    <div className="practice-mode">
      <h3>Režim procvičování</h3>
      <h2>{question.question}</h2>
      <div className="answer-display">{answer}</div>
      {feedback && (
        <div className={`feedback ${feedback === 'Chybně!' ? 'incorrect' : 'correct'}`}>
          {feedback} {feedback === 'Chybně!' && correctAnswer}
        </div>
      )}
      <TouchKeyboard onKeyPress={handleKeyPress} />
      <button className="exit-button" onClick={onExit}>Ukončit</button>
    </div>
  );
};

export default PracticeMode;
