import React, { useState, useEffect } from 'react';
import TouchKeyboard from './TouchKeyboard';
import './TestingMode.css';

const TestingMode = ({ upperLimit, operations, onExit }) => {
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(null);
  const [isTestComplete, setIsTestComplete] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);

  const totalQuestions = operations.length >= 3 ? 15 : 10;
  const questionsPerOperation = Math.ceil(totalQuestions / operations.length);

  useEffect(() => {
    generateQuestion();
  }, []);

  const generateQuestion = () => {
    setQuestion(createQuestion());
    setAnswer('');
    setInputDisabled(false); // Povolit vstup pro novou otázku
  };

  const createQuestion = () => {
    const operation = operations[questionIndex % operations.length];
    let a = Math.floor(Math.random() * upperLimit[operation]) + 1;
    let b = Math.floor(Math.random() * upperLimit[operation]) + 1;
    let question = '';
    let answer = 0;

    switch (operation) {
      case 'addition':
        question = `${a} + ${b}`;
        answer = a + b;
        break;
      case 'subtraction':
        if (a < b) [a, b] = [b, a]; // Ensure positive result
        question = `${a} - ${b}`;
        answer = a - b;
        break;
      case 'multiplication':
        question = `${a} x ${b}`;
        answer = a * b;
        break;
      case 'division':
        while (a % b !== 0) {
          a = Math.floor(Math.random() * upperLimit[operation]) + 1;
          b = Math.floor(Math.random() * upperLimit[operation]) + 1;
        }
        question = `${a} / ${b}`;
        answer = a / b;
        break;
      case 'rounding':
        let num = Math.floor(Math.random() * upperLimit[operation]) + 1;
        let rounded = Math.round(num / 10) * 10;
        question = `${num} ≐ ?`;
        answer = rounded;
        break;
      default:
        break;
    }
    return { question, answer };
  };

  const checkAnswer = () => {
    if (parseInt(answer) === question.answer) {
      setCorrectAnswers(correctAnswers + 1);
    } else {
      const correctDisplay = question.question.replace('?', question.answer);
      setIncorrectQuestions([
        ...incorrectQuestions,
        { question: question.question, correctDisplay }
      ]);
    }
    if (questionIndex + 1 < totalQuestions) {
      setQuestionIndex(questionIndex + 1);
      setInputDisabled(true); // Zakázat vstup během zpracování odpovědi
      generateQuestion();
    } else {
      setEndTime(Date.now());
      setIsTestComplete(true);
    }
  };

  const handleKeyPress = (key) => {
    if (inputDisabled) return; // Ignorovat vstup, pokud je vstup zakázán
    if (key === 'Backspace') {
      setAnswer(answer.slice(0, -1));
    } else if (key === 'OK') {
      checkAnswer();
    } else {
      setAnswer(answer + key);
    }
  };

  const getTotalTime = () => {
    const totalTime = endTime - startTime;
    const seconds = Math.floor(totalTime / 1000) % 60;
    const minutes = Math.floor(totalTime / 60000);
    return `${minutes} minut a ${seconds} sekund`;
  };

  return (
    <div className="testing-mode">
      <h3>Režim zkoušení</h3>
      {isTestComplete ? (
        <div>
          <h2>Výsledek testu</h2>
          <p>Počet položených otázek: {totalQuestions}</p>
          <p>Počet správně zodpovězených otázek: {correctAnswers}</p>
          <p>Čas: {getTotalTime()}</p>
          {incorrectQuestions.length > 0 && (
            <div>
              <h3>Něco se nepovedlo, ale to nevadí. Tady máš správné odpovědi.</h3>
              <ul>
                {incorrectQuestions.map((item, index) => (
                  <li key={index}>{item.correctDisplay}</li>
                ))}
              </ul>
            </div>
          )}
          <button className="exit-button" onClick={onExit}>Ukončit</button>
        </div>
      ) : (
        <div>
          <h2>{question.question}</h2>
          <div className="answer-display">{answer}</div>
          <TouchKeyboard onKeyPress={handleKeyPress} />
          <button className="exit-button" onClick={onExit}>Ukončit</button>
        </div>
      )}
    </div>
  );
};

export default TestingMode;
