import React from 'react';
import './TouchKeyboard.css';

const TouchKeyboard = ({ onKeyPress }) => {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'OK'];

  return (
    <div className="touch-keyboard">
      {keys.map((key) => (
        <button
          key={key}
          onClick={() => onKeyPress(key)}
          className="keyboard-key"
        >
          {key === 'Backspace' ? '←' : key}
        </button>
      ))}
    </div>
  );
};

export default TouchKeyboard;
