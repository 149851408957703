import React, { useState } from 'react';
import PracticeMode from './PracticeMode';
import TestingMode from './TestingMode';
import OperationSelection from './OperationSelection';
import LimitSelection from './LimitSelection';
import './App.css';

const App = () => {
  const [mode, setMode] = useState(null);
  const [operations, setOperations] = useState([]);
  const [limits, setLimits] = useState({});

  const handleModeSelect = (selectedMode) => {
    setMode(selectedMode);
  };

  const handleExit = () => {
    setMode(null);
    setOperations([]);
    setLimits({});
  };

  const handleOperationProceed = () => {
    setMode('limit-selection');
  };

  const handleLimitProceed = () => {
    setMode('select-mode');
  };

  return (
    <div className="App">
      <h1>Procvičování z matematiky (1. a 2. třída)</h1>
      {!mode ? (
        <OperationSelection setOperations={setOperations} onProceed={handleOperationProceed} />
      ) : mode === 'limit-selection' ? (
        <LimitSelection operations={operations} setLimits={setLimits} onProceed={handleLimitProceed} />
      ) : mode === 'select-mode' ? (
        <div>
          <button className="mode-button" onClick={() => handleModeSelect('practice')}>Procvičování</button>
          <button className="mode-button" onClick={() => handleModeSelect('testing')}>Zkoušení</button>
          <p className="help-text">
            Tady si vyber, zda chceš spustit Procvičování nebo Zkoušení.<br/>
            Při Procvičování se ti zobrazují početní příklady. Naťukáš výsledek a dozvíš se, jestli jsi odpověděl správně, nebo chybně. V případě správné odpovědi se ti za 3 sekundy objeví nový příklad. Pokud se ti odpověď nepovedla, hned se ti ukáže správné řešení a ty budeš mít 7 sekund na to si ho prohlédnout. Poté se objeví nový příklad. Takto dostaneš 20-30 příkladů, podle toho, jak ti to půjde.<br/>V režimu Zkoušení dostaneš 10 příkladů a jestli jsi to spočetl správně, se dozvíš až na konci. Uvidíš počet správných odpovědí a také čas, který jsi na spočítání potřeboval.
          </p>
        </div>
      ) : mode === 'practice' ? (
        <PracticeMode upperLimit={limits} operations={operations} onExit={handleExit} />
      ) : mode === 'testing' ? (
        <TestingMode upperLimit={limits} operations={operations} onExit={handleExit} />
      ) : null}
    </div>
  );
};

export default App;
