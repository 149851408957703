import React, { useState } from 'react';
import './OperationSelection.css';

const OperationSelection = ({ setOperations, onProceed }) => {
  const operations = [
    { name: 'Sčítání', value: 'addition' },
    { name: 'Odčítání', value: 'subtraction' },
    { name: 'Násobení', value: 'multiplication' },
    { name: 'Dělení', value: 'division' },
    { name: 'Zaokrouhlování', value: 'rounding' }
  ];

  const [selectedOperations, setSelectedOperations] = useState([]);

  const handleOperationClick = (value) => {
    setSelectedOperations((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((op) => op !== value)
        : [...prevSelected, value]
    );
  };

  const handleProceed = () => {
    setOperations(selectedOperations);
    onProceed();
  };

  return (
    <div className="operation-selection">
      <h2>Vyberte typ operace</h2>
      <div className="operations-grid">
        {operations.map((op) => (
          <div
            key={op.value}
            className={`operation-tile ${selectedOperations.includes(op.value) ? 'selected' : ''}`}
            onClick={() => handleOperationClick(op.value)}
          >
            {op.name}
          </div>
        ))}
      </div>
      <button onClick={handleProceed}>Pokračovat</button>
      <p className="help-text">
        Vítej v aplikaci na procvičování a zkoušení z počtů. Napřed si vyber, zda chceš sčítat, odčítat, násobit, dělit nebo zaokrouhlovat. Výběr provedeš kliknutím na příslušnou dlaždici. Můžeš vybrat více operací najednou (klidně všechny dohromady). Až budeš mít vybráno, klikni na tlačítko Pokračovat.
      </p>
    </div>
  );
};

export default OperationSelection;
